import { useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import useUser from "../lib/useUser";
import { Layout } from "../common/Layout";
// import Form from "../components/Form";
import fetchJson from "../lib/fetchJson";

import {
  Backdrop,
  CircularProgress,
  Alert,
  Container,
  Grid,
  Typography,
  Tab,
  Button,
  Divider,
} from "@mui/material";

const Login = () => {
  const { mutateUser } = useUser({
    redirectTo: "/admin",
    redirectIfFound: true,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  async function handleSubmit(e) {
    setIsLoading(true);
    e.preventDefault();

    const body = {
      email: e.currentTarget.email.value,
      password: e.currentTarget.password.value,
    };

    try {
      await mutateUser(
        fetchJson("/api/auth/login", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        })
      );
      console.log("AFter mutateUser");
      window.location = "/admin";
    } catch (error) {
      console.error("An unexpected error happened:", error);
      setErrorMsg(error.data.message);
    }
    setIsLoading(true);
  }

  if (typeof window === "undefined") {
    return null;
  }

  return (
    <Layout>
      {isLoading ? <CustomBackdrop /> : null}
      <div className="login">
        <Form isLogin errorMessage={errorMsg} onSubmit={handleSubmit} />
        {/* <Link href="/api/auth/login">Login</Link> */}
      </div>
      <style jsx>{`
        .login {
          max-width: 21rem;
          margin: 0 auto;
          padding: 1rem;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      `}</style>
    </Layout>
  );
};

const CustomBackdrop = () => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const Form = ({ errorMessage, onSubmit }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const [state, setState] = useState({
    email: urlParams.get("email"),
    password: urlParams.get("password"),
  });
  const handleChange = (path) => (e) => {
    setState({
      [path]: e.target.value,
    });
  };
  return (
    <form onSubmit={onSubmit}>
      <label>
        <span>Type your username</span>
        <input
          type="text"
          name="email"
          required
          value={state.email}
          onChange={handleChange("email")}
        />
        <input
          type="password"
          name="password"
          required
          value={state.password}
          onChange={handleChange("password")}
        />
      </label>

      <button type="submit">Login</button>

      {errorMessage && <p className="error">{errorMessage}</p>}

      <style jsx>{`
        form,
        label {
          display: flex;
          flex-flow: column;
        }
        label > span {
          font-weight: 600;
        }
        input {
          padding: 8px;
          margin: 0.3rem 0 1rem;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
        .error {
          color: brown;
          margin: 1rem 0 0;
        }
      `}</style>
    </form>
  );
};

export default Login;
